<template>
  <Kc-Dialog
    :visible.sync="visible"
    class="dialogView"
    :width="width"
    :title="title"
    :showClose="false"
  >
    <slot>
      <div class="content">
        {{ msg }}
      </div>
    </slot>
    <slot name="footer">
      <div class="dialogFooter">
        <div class="dialogBtn rejure" @click="visible = false">取消</div>
        <el-button
          type="primary"
          class="dialogBtn sure"
          round
          :disable="disable"
          :loading="disable"
          @click="sureAction"
          >确定</el-button
        >
      </div>
    </slot>
  </Kc-Dialog>
</template>

<script>
export default {
  name: "dialogView",
  props: {
    msg: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "提示",
    },
    width: {
      type: String,
      default: "90%",
    },
    height: {
      type: String,
      default: "90%",
    },
    sureEvent: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      visible: false,
      disable: false,
    };
  },
  methods: {
    showOrHidden: function (show) {
      this.visible = show;
    },
    async sureAction() {
      this.disable = true;
      await this.sureEvent();
      this.visible = false;
      this.disable = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.dialogView {
  .content {
    width: 100%;
    text-align: center;
    color: $color1;
    font-size: 14px;
    height: 96px;
    line-height: 96px;
    margin-top: -16px;
  }
  .dialogFooter {
    text-align: center;
    padding: 0 4px;
    display: flex;
    justify-content: space-between;
    .dialogBtn {
      display: inline-block;
      width: 110px;
      margin: 0px 10px 0px 10px;
      height: 40px;
      line-height: 40px;
      border-radius: 20px;
      text-align: center;
      color: white;
      padding: 0;
      font-size: 16px;
    }
    .sure {
      background-color: #3a80f7;
    }
    .rejure {
      border: 1px solid #3a80f7;
      background-color: #ffffff;
      color: #3a80f7;
    }
  }
}
</style>
